export default {
    installURL: function () {
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('edge') != -1) {
            return "Edge";
        } else if (userAgent.indexOf('chrome') != -1) {
            return "Chrome";
        } else if (userAgent.indexOf('firefox') != -1) {
            return "Firefox";
        } else {
            return "Chrome";
        }
    }
}